import React from "react";
import { Link } from "gatsby";

import styles from "./success.module.css";
import Layout from "../components/Layout";

import { usePageTracking } from "../utils/usePageTracking";

function Success({ location }) {
  usePageTracking();

  if (!location.state) {
    // if there is no state, it means the user got here withou sending the order
    // so we show an error message as a fallback

    return (
      <Layout>
        <main className={styles.container}>
          <h2>Oops! Something went wrong</h2>
          <Link to="/">Proizvodi</Link>
        </main>
      </Layout>
    );
  }

  const { data } = location.state;

  return (
    <Layout>
      <main className={styles.container}>
        <h2>
          Hvala Vam na upitu, {data.name}{" "}
          <span role="img" aria-label="party popper">
            🎉
          </span>
        </h2>
        <p>
          Poslali smo potvrdu o zaprimljenom upitu na <b>{data.email}</b>. Na
          upite obično odgovaramo unutar jednog dana.
        </p>
        <ul>
          {data["order-list"].split("\n").map((item, idx) => {
            return item !== "" && <li key={idx}>{item}</li>;
          })}
        </ul>
      </main>
    </Layout>
  );
}

export default Success;
